import Data from '@/model/common/Data'
import {
  ReqCustomerClass,
  RegCustomerList,
  GetCustomerDetail,
} from '@/model/customerManagement/customer'
import { MissingType } from '@/types/comm'
import { $axios } from '../config/axios'
class CustomerManagementApi {
  GetCustomerList(data: ReqCustomerClass): Promise<RegCustomerList> {
    return $axios.post('/api/bcic/customer/list', data)
  }
  GetCustomerDetail(data: unknown): Promise<GetCustomerDetail> {
    return $axios.post('/api/bcic/customer/detail', data)
  }
  CustomerFollowAdd(data: unknown): Promise<unknown> {
    return $axios.post('/api/customer/follow/add', data)
  }
  CustomerFollowCancel(data: unknown): Promise<unknown> {
    return $axios.post('/api/customer/follow/cancel', data)
  }
  GetCustomerIndustry(data: unknown): Promise<Data> {
    return $axios.post('/api/bcic/customer/industry', data)
  }
  // 新增客户
  AddCustomer(id: string, status: 'MARKET' | 'IPO'): Promise<Data> {
    return $axios.post(`/api/bcic/customer/add`, { id, status })
  }
  // 将IPO公司扭转上市
  TurnMarket(id: string): Promise<Data> {
    return $axios.post(`/api/bcic/customer/turnMarket?id=${id}`, {})
  }
  // 根据天眼查客户id更新客户信息
  UpdateCustomer(id: string): Promise<Data> {
    return $axios.post(`/api/bcic/customer/update`, { id: id + '' })
  }
  // 客户导出
  ExportCustomerInfo(data: unknown): Promise<MissingType> {
    return $axios.post(`/api/bcic/customer/exportCustomerInfo`, data, {
      responseType: 'arraybuffer',
    })
  }
  // 导入
  ImportCustomerInfo(data: FormData): Promise<Blob> {
    return $axios.post(`/api/bcic/customer/import`, data)
  }
  // 删除ipo
  DeleteCustomerInfo(data: string[]): Promise<unknown> {
    return $axios.delete(`/api/bcic/customer/delete`, { data })
  }
}
// 单列模式返回对象
let customerManagementApi
export default (() => {
  if (customerManagementApi) return customerManagementApi
  customerManagementApi = new CustomerManagementApi()
  return customerManagementApi
})()
