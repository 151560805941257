
import { defineComponent, toRefs, ref, reactive, onMounted } from 'vue'
import detailLayout from '@/components/detailLayout/index.vue'
import charts from '@/components/echarts/echarts.vue'
import customerManagementApi from '@/axios/api/customerManagement'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import Data from '@/model/common/Data'
import { CustomerDetail, GetCustomerDetail } from '@/model/customerManagement/customer'
import { debounce } from 'lodash-es'
import { MissingType } from '@/types/comm'
interface SeriesItem {
  name: string
  type: 'line'
  data: number[]
  id: string
}
interface OptionsItem {
  legend: {
    data: string[]
  }
  xAxis: {
    type: 'category'
    boundaryGap: true
    data: string[]
  }
  series: SeriesItem[]
}
interface OptionsList {
  [key: string]: OptionsItem
}
interface ColumnItem {
  title: string
  dataIndex: string
  key: string
  suffix?: string
  hint?: string
}
interface ColumnObj {
  [key: string]: ColumnItem[]
}
export default defineComponent({
  components: {
    detailLayout,
    charts,
  },
  setup() {
    const echarts = ref()
    const activeKey = ref('')
    const pageData = reactive({
      tabList: [
        {
          key: '1',
          name: '成长能力',
        },
        {
          key: '2',
          name: '盈利能力',
        },
        {
          key: '3',
          name: '财务风险',
        },
        {
          key: '4',
          name: '营运能力',
        },
      ],
      dataSource: [
        { showYear: '2021' },
        { showYear: '2020' },
        { showYear: '2019' },
        { showYear: '2018' },
      ] as MissingType[],
      columns: {
        '1': [
          {
            title: '项目/年度',
            dataIndex: 'showYear',
            key: 'showYear',
            suffix: '年度',
          },
          {
            title: '营业总收入(元)',
            dataIndex: 'totalRevenue',
            key: 'totalRevenue',
            suffix: '万',
            hint: '营业收入=主营业务收入+其他业务收入\n从事主营业务和其他业务所取得的收入',
          },
          {
            title: '营业总收入同比增长(%)',
            dataIndex: 'revenueYoy',
            key: 'revenueYoy',
            suffix: '%',
            hint: '营业收入同比增长率=（当期营业收入-上期营业收入)÷上期营业收入×100%',
          },
          {
            title: '毛利润(元)',
            dataIndex: 'profitTotalAmt',
            key: 'profitTotalAmt',
            suffix: '',
            hint: '主营业务利润（也叫毛利润）=主营业务收入－主营业务成本',
          },
          {
            title: '营业净利润（元）',
            dataIndex: 'netProfit',
            key: 'netProfit',
            suffix: '',
            hint: '营业净利润=主营业务收入－主营业务成本-应缴所得税款\n正数表示盈利，负数表示亏损。',
          },
          {
            title: '营业利润总额增长率(%)',
            dataIndex: 'netProfitAtsopcYoy',
            key: 'netProfitAtsopcYoy',
            suffix: '%',
            hint: '营业利润总额增长率=（本年营业利润总额-上年营业利润总额）÷上年营业利润总额×100%',
          },
          {
            title: '总资产(元)',
            dataIndex: 'totalAssets',
            key: 'totalAssets',
            suffix: '',
            hint: '总资产=资产负债表的“资产总计”金额\n总资产是指某一经济实体拥有或控制的、能够带来经济利益的全部资产',
          },
          {
            title: '总资产增长率(%)',
            dataIndex: 'totalAssetsRate',
            key: 'totalAssetsRate',
            suffix: '%',
            hint: '总资产增长率=本年总资产增长额/年初资产总额×100%\n正数表示增加，负数表示减少。正数越大，增加的越多。',
          },
        ],
        '2': [
          {
            title: '项目/年度',
            dataIndex: 'showYear',
            key: 'showYear',
            suffix: '年度',
          },
          {
            title: '营业总收入(元)',
            dataIndex: 'revenue',
            key: 'revenue',
            suffix: '',
            hint: '营业收入=主营业务收入+其他业务收入\n从事主营业务和其他业务所取得的收入',
          },
          {
            title: '营业总成本(元)',
            dataIndex: 'operatingCosts',
            key: 'operatingCosts',
            suffix: '',
            hint: '营业总成本=利润表的营业总成本\n营业成本主要包括主营业务成本、其他业务成本。',
          },
          {
            title: '营业净利润（元）',
            dataIndex: 'netProfit',
            key: 'netProfit',
            suffix: '',
            hint: '营业净利润=主营业务收入－主营业务成本-应缴所得税款\n正数表示盈利，负数表示亏损。',
          },
          {
            title: '净利润增长率（%）',
            dataIndex: 'netSellingRate',
            key: 'netSellingRate',
            suffix: '%',
            hint: '净利润增长率=（本年净利润-上年净利润）÷上年净利润×100%',
          },
          {
            title: '总资产净利率(%)',
            dataIndex: 'netInterestOfTotalAssets',
            key: 'netInterestOfTotalAssets',
            suffix: '%',
            hint: '总资产净利率(%)=当年净利润÷当年总资产×100%\n当年度利润所在总资产内所占比率',
          },
          {
            title: '经营现金流量净额',
            dataIndex: 'ncfFromOa',
            key: 'ncfFromOa',
            suffix: '',
            hint: '经营活动产生的现金流量净额=经营活动的现金流入小计-经营活动的现金流出小计。',
          },
        ],
        '3': [
          {
            title: '项目/年度',
            dataIndex: 'showYear',
            key: 'showYear',
            suffix: '年度',
          },
          {
            title: '流动比率(%)',
            dataIndex: 'currentRatio',
            key: 'currentRatio',
            suffix: '%',
            hint: '流动比率=流动资产÷流动负债×100%\n流动比率越高，企业的变现能力越强。',
          },
          {
            title: '速动比率(%)',
            dataIndex: 'quickRatio',
            key: 'quickRatio',
            suffix: '%',
            hint: '速动比率=(流动资产－存货净额)÷流动负债×100%\n速动比率越高越好，相比流动比，消除了现货等资产变现能力差的影响',
          },
          {
            title: '现金流量比率(%)',
            dataIndex: 'cashFlowRate',
            key: 'cashFlowRate',
            suffix: '%',
            hint: '现金流量比率=(流动资产－存货净额-应收账款)÷流动负债×100%\n越高越好，仅代表货币的流动比，表示直接偿付能力',
          },
          {
            title: '资产负债率(%)',
            dataIndex: 'assetLiabRatio',
            key: 'assetLiabRatio',
            suffix: '%',
            hint: '资产负债率= (负债总额÷资产总额)×100%\n长期偿债能力，越低越好，越有保障',
          },
        ],
        '4': [
          {
            title: '项目/年度',
            dataIndex: 'showYear',
            key: 'showYear',
            suffix: '年度',
          },
          {
            title: '总资产周转天数',
            dataIndex: 'totalAssetTurnoverDays',
            key: 'totalAssetTurnoverDays',
            suffix: '',
            hint: '总资产周转天数',
          },
          {
            title: '存货周转天数',
            dataIndex: 'inventoryTurnoverDays',
            key: 'inventoryTurnoverDays',
            suffix: '',
            hint: '（本年营业总收入-上年营业总收入）/去年营业总收入',
          },
          {
            title: '应收账款周转天数',
            dataIndex: 'receivableTurnoverDays',
            key: 'receivableTurnoverDays',
            suffix: '',
            hint: '当年度通过营业所获得的利润',
          },
          {
            title: '总资产周转率',
            dataIndex: 'totalCapitalTurnover',
            key: 'totalCapitalTurnover',
            suffix: '%',
            hint: '总资产周转天数/365=周转率',
          },
          {
            title: '存货周转率',
            dataIndex: 'inventoryTurnoverRate',
            key: 'inventoryTurnoverRate',
            suffix: '%',
            hint: '当年度通过营业所获得的净利润，利润总额-应缴税款',
          },
          {
            title: '应收账款周转率',
            dataIndex: 'accountsReceivableTurnover',
            key: 'accountsReceivableTurnover',
            suffix: '%',
            hint: '应收账款周转天数/365=周转率',
          },
        ],
      } as ColumnObj,
      customerDetail: {
        bcicCustomerInfoVO: {
          isMarket: 1,
        },
        bcicCustomerDetailVOList: [] as CustomerDetail[],
      } as GetCustomerDetail,
      optionsList: {
        '1': {
          legend: { data: ['总营收', '总利润', '总资产', '净利润'] },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: ['2018', '2019', '2020', '2021'],
          },
          series: [
            {
              name: '总营收',
              type: 'line',
              data: [20, 20, 20, 20],
              id: 'totalRevenue',
            },
            {
              name: '总利润',
              type: 'line',
              data: [14.3, 14.6, 14.9, 60],
              id: 'profitTotalAmt',
            },
            {
              name: '总资产',
              type: 'line',
              data: [15, 19, 20, 21],
              id: 'totalAssets',
            },
            {
              name: '净利润',
              type: 'line',
              data: [3, 10, 2, 10],
              id: 'netProfit',
            },
          ],
        },
        '2': {
          legend: { data: ['营业收入', '营业成本', '营业净利润', '经营现金流量净额'] },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: ['2018', '2019', '2020', '2021'],
          },
          series: [
            {
              name: '营业收入',
              type: 'line',
              data: [20, 20, 20, 20],
              id: 'revenue',
            },
            {
              name: '营业成本',
              type: 'line',
              data: [14.3, 14.6, 14.9, 60],
              id: 'operatingCosts',
            },
            {
              name: '营业净利润',
              type: 'line',
              data: [15, 19, 20, 21],
              id: 'netProfit',
            },
            {
              name: '经营现金流量净额',
              type: 'line',
              data: [3, 10, 2, 10],
              id: 'ncfFromOa',
            },
          ],
        },
        '3': {
          legend: { data: ['流动比率', '速动比率', '现金流量比率', '资产负债率'] },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: ['2018', '2019', '2020', '2021'],
          },
          series: [
            {
              name: '流动比率',
              type: 'line',
              data: [20, 20, 20, 20],
              id: 'currentRatio',
            },
            {
              name: '速动比率',
              type: 'line',
              data: [14.3, 14.6, 14.9, 60],
              id: 'quickRatio',
            },
            {
              name: '现金流量比率',
              type: 'line',
              data: [15, 19, 20, 21],
              id: 'cashFlowRate',
            },
            {
              name: '资产负债率',
              type: 'line',
              data: [3, 10, 2, 10],
              id: 'assetLiabRatio',
            },
          ],
        },
        '4': {
          legend: { data: ['总资产周转天数', '存货周转天数', '应收款周转天数'] },
          xAxis: {
            type: 'category',
            boundaryGap: true,
            data: ['2018', '2019', '2020', '2020'],
          },
          series: [
            {
              name: '总资产周转天数',
              type: 'line',
              data: [20, 20, 20, 20],
              id: 'totalAssetTurnoverDays',
            },
            {
              name: '存货周转天数',
              type: 'line',
              data: [14.3, 14.6, 14.9, 60],
              id: 'inventoryTurnoverDays',
            },
            {
              name: '应收款周转天数',
              type: 'line',
              data: [15, 19, 20, 21],
              id: 'receivableTurnoverDays',
            },
          ],
        },
      } as OptionsList,
      options: {
        tooltip: {
          trigger: 'axis',
        },
        legend: {
          data: ['总营收', '总利润', '总资产', '净利润'],
          top: '16px',
          left: '22px',
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          type: 'category',
          boundaryGap: true,
          data: ['2018', '2019', '2020', '2021'],
        },
        yAxis: {
          type: 'value',
          // data: [2, 4, 6, 8, 10, 12, 14, 16, 18, 20, 22],
        },
        series: [
          {
            name: '总营收',
            type: 'line',
            data: [0, 0, 0, 0],
          },
          {
            name: '总利润',
            type: 'line',
            data: [0, 0, 0, 0],
          },
          {
            name: '总资产',
            type: 'line',
            data: [0, 0, 0, 0],
          },
          {
            name: '净利润',
            type: 'line',
            data: [0, 0, 0, 0],
          },
        ],
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    })
    const tabChange = (key: string) => {
      console.log(key)
      activeKey.value = key
      pageData.options.legend.data = pageData.optionsList[key]?.legend.data
      pageData.options.xAxis.data = pageData.optionsList[key]?.xAxis.data
      pageData.options.series = pageData.optionsList[key]?.series
    }
    let route = useRoute()
    customerManagementApi
      .GetCustomerDetail({ id: route.params.id })
      .then((res) => {
        res.bcicCustomerDetailVOList.sort((a: CustomerDetail, b: CustomerDetail) => {
          return +(a.showYear as string) - +(b.showYear as string)
        })
        pageData.customerDetail.bcicCustomerInfoVO = res.bcicCustomerInfoVO
        pageData.customerDetail.bcicCustomerDetailVOList = res.bcicCustomerDetailVOList
        //遍历四种图表数据
        Object.keys(pageData.optionsList).forEach((item) => {
          let xData = pageData.customerDetail.bcicCustomerDetailVOList.map((xItem) => {
            return xItem.showYear
          })
          //给每个图表的横坐标赋值
          pageData.optionsList[item].xAxis.data = xData as string[]
          //遍历每个图表的数据 通过对应的id赋值
          pageData.optionsList[item].series.forEach((serItem: SeriesItem) => {
            serItem.data = pageData.customerDetail.bcicCustomerDetailVOList.map((xItem: Data) => {
              return parseFloat(xItem[serItem.id as string] as string) || 0
            })
          })
        })
        console.log(pageData.customerDetail)
        tabChange('1')
      })
      .catch((err) => {
        message.error(err)
      })
    onMounted(() => {
      window.onresize = debounce(function () {
        console.log('缩放了')
        echarts.value.resize()
      }, 500)
    })
    return {
      ...toRefs(pageData),
      tabChange,
      activeKey,
      img: require('@/assets/img/暂无数据.png'),
      echarts,
    }
  },
})
